import React, { useState } from "react";
import { StructuredText } from "react-datocms";
import Recaptcha from "react-google-recaptcha";
import theme from "../styles/theme";

const RECAPTCHA_KEY = "6Ld4-DMqAAAAAJCz8qbLljXJgzPY7pkrRlIuMgQj";

const ContactForm = ({ data }) => {
  const [formData, setFormData] = useState({
    name: "",
    sähköposti: "",
    phone: "",
    company: "",
    message: "",
    botField: "",
  });
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [formStatus, setFormStatus] = useState("");

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (formData.botField) {
      // If the honeypot field is filled, it's a bot submission, so ignore it
      return;
    }

    if (!recaptchaToken) {
      alert("Please complete the reCAPTCHA.");
      return;
    }

    if (
      formData.name.trim() &&
      formData.sähköposti.trim() &&
      formData.message.trim()
    ) {
      const formDataObject = new FormData();

      const formWithRecaptcha = {
        ...formData,
        "g-recaptcha-response": recaptchaToken,
      };

      formDataObject.append("form-name", "Contact Form");

      Object.keys(formWithRecaptcha).forEach(key => {
        formDataObject.append(key, formWithRecaptcha[key]);
      });

      try {
        const response = await fetch("/", {
          method: "POST",
          body: formDataObject,
        });

        if (response.ok) {
          setFormStatus("success");
        } else {
          setFormStatus("error");
        }
      } catch (error) {
        console.log("Error:", error);
        setFormStatus("error");
      }
    } else {
      setFormStatus("formNotFilled");
    }
  };

  const onRecaptchaChange = token => {
    setRecaptchaToken(token);
  };

  return (
    <div
      className="formBox"
      css={`
        flex: 1;
        flex-direction: column;
        border-radius: 10px;
        padding: 40px 40px;

        ${theme.mobile} {
          padding: 20px !important;
        }
        .content {
          max-width: 550px;
          margin-bottom: 40px;
          h1,
          h2 {
            color: ${theme.pink};
          }
          strong {
            font-size: 18px;
          }
        }
        form {
          display: flex;
          flex-direction: column;
          max-width: 440px;
          input,
          textarea {
            border: 3px solid #e00084;
            height: 52px;
            padding: 8px;
            border-radius: 5px;
            font-size: 18px;
            margin: 5px 0 5px;
            &:active,
            &:focus {
              border: 3px solid #e00084;
            }
          }
          .buttonSubmit {
            background: #e00084;
            color: #fff;
            height: 52px;
            width: 230px;
            margin-top: 20px;
            &:hover {
              opacity: 0.8;
              transition: opacity 0.2s;
            }
            &:disabled {
              opacity: 1 !important;
              cursor: default !important;
            }
          }
        }
        .people {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding-left: 40px;
        }
      `}
    >
      {data ? (
        <div className="content">
          {" "}
          <StructuredText data={data} />
        </div>
      ) : (
        <div class="content">
          <h2>Do you like what you see?</h2>
          <p>
            <strong>We want to help you. </strong>
          </p>
          <p>
            Please send us a message and we’ll show you how our magical gadgets
            will significantly improve your business.{" "}
          </p>
        </div>
      )}

      <form
        name="Contact Form"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="Contact Form" />
        <p style={{ display: "none" }}>
          <label>
            Don’t fill this out if you're human:{" "}
            <input name="botField" onChange={e => handleChange(e)} />
          </label>
        </p>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Name (required)"
          required
          onChange={e => handleChange(e)}
        />
        <input
          type="text"
          name="sähköposti"
          id="sähköposti"
          placeholder="Email (required)"
          required
          onChange={e => handleChange(e)}
        />
        <input
          type="text"
          name="phone"
          id="phone"
          placeholder="Phone"
          onChange={e => handleChange(e)}
        />
        <input
          type="text"
          name="company"
          id="company"
          placeholder="Company"
          onChange={e => handleChange(e)}
        />
        <textarea
          type="text"
          name="message"
          id="message"
          placeholder="Message (required)"
          rows="3"
          required
          onChange={e => handleChange(e)}
        />
        <a
          css={`
            margin-top: 24px;
            margin-bottom: 8px;
            text-decoration: underline;
          `}
          href="/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <div data-netlify-recaptcha="true">
          <Recaptcha sitekey={RECAPTCHA_KEY} onChange={onRecaptchaChange} />
        </div>
        <button className="buttonSubmit" type="submit">
          Send
        </button>

        {formStatus === "success" && (
          <p className="messages">
            <strong>Thank you, we'll be in touch!</strong>
          </p>
        )}
        {formStatus === "error" && (
          <p className="messages">
            <strong>Something went wrong. Please, try again!</strong>
          </p>
        )}
        {formStatus === "formNotFilled" && (
          <p className="messages">
            <strong>Please fill required fields.</strong>
          </p>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
